import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { MdOutlineAccountCircle } from "react-icons/md";
import { AiOutlineHome, AiFillHome } from "react-icons/ai";
import { MdHelpOutline, MdOutlineHelp } from "react-icons/md";
import { RiShoppingCart2Line, RiShoppingCart2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import { Sidebar } from "../AuthSidebar/Sidebar";
import { setSidebar } from "../../reducer/slices/authSlice";
import logo from "../../assets/logo/logo.png";

const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Categories",
    path: "",
  },
];

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { sidebar } = useSelector((state) => state.auth);
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);

  const matchRoute = (path) => {
    return matchPath(path, location.pathname);
  };
  return (
    <div className="flex w-full justify-center items-center z-[100]">
      <div className="navbar w-full h-[65px] md:h-[90px] fixed top-0 bg-white flex items-center justify-center">
        <div className=" flex w-11/12 max-w-maxContent items-center justify-between md:justify-between">
          <div className="flex items-center gap-x-5">
            {/* Image */}
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                loading="lazy"
                className="w-14 md:w-16 lg:w-18"
              />
            </Link>

            <nav className="hidden md:flex">
              <ul className="flex gap-x-4 text-black font-sans">
                {NavbarLinks.map((link, index) => (
                  <li key={index}>
                    <Link to={link?.path}>
                      <div
                        className={`relative flex gap-x-1 ${
                          matchRoute(link?.path)
                            ? "text-black/70"
                            : "text-black/60"
                        }`}
                      >
                        <div className={`text-xl`}>
                          {matchRoute(link?.path) ? link.activeIcon : link.icon}
                        </div>
                        <p>{link.title}</p>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div>
            {token === null && (
              <div
                className="flex items-center gap-x-1 cursor-pointer"
                onClick={() => dispatch(setSidebar(true))}
              >
                <div className="text-xl">
                  <MdOutlineAccountCircle />
                </div>
                <p>Sign In</p>
              </div>
            )}
            {token && user.account_type === "Customer" && (
              <Link to="/customer/account">
                <Avatar
                  style={{ backgroundColor: "#87d068" }}
                  icon={<UserOutlined />}
                />
              </Link>
            )}
            {token && user.account_type === "Admin" && (
              <Link to="/dashboard/admin">
                <button className="text-white text-sm sm:text-base py-1.5 px-2.5 sm:py-2 sm:px-4 bg-primaryColor">
                  Admin Dashboard
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center md:hidden w-full fixed bottom-0 z-[1000] border border-black/20 bg-white">
        <nav className="flex w-full">
          <ul className="w-full h-[60px] flex items-center justify-evenly text-black font-sans font-light">
            {NavbarLinks.map((link, index) => (
              <li key={index}>
                <Link to={link?.path}>
                  <div className="relative flex flex-col items-center text-sm">
                    <div className={`text-xl text-black/60`}>
                      {matchRoute(link?.path) ? link.activeIcon : link.icon}
                    </div>
                    <p className="text-black/65">{link.title}</p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {sidebar && <Sidebar />}
    </div>
  );
};

export default Navbar;
