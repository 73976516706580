import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import Body from "./Body";
import toast from "react-hot-toast";
import { Container } from "../../components/DashbordSideBar";
import { useSelector } from "react-redux";
import {
  changeUniversalAvailable,
  changeAvailable,
  createProduct,
  deleteProduct,
  getAllProducts,
  updateProduct,
  updateProductQuntity,
} from "../../services/operations/productApi";
import { getAllCategory } from "../../services/operations/categoryApi";
import API from "../../services/api";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const socket = io.connect(BASE_URL);

const AddProduct = () => {
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [editProductModal, setEditProductModal] = useState(false);
  const [addProductModalData, setAddProductModalData] = useState({
    product_name: "",
    product_description: "",
    product_price: "",
    product_category: "",
    product_quantity: "",
  });
  const [addProductImage, setAddProductImage] = useState(null);
  const [editProductModalData, setEditProductModalData] = useState({
    product_id: "",
    product_name: "",
    product_description: "",
    product_price: "",
    product_category: "",
    product_quantity: "",
  });
  const [editProductImageData, setEditProductImageData] = useState(null);

  const uploadImageFile = (e) => {
    console.log(e);
    if (e.target.files.length > 0) {
      setAddProductImage({
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      });
    }
  };

  const addProductModalToggle = () => {
    setAddProductModal(!addProductModal);
  };

  const openEditProductModal = (
    product_id,
    product_name,
    product_description,
    product_price,
    product_category,
    product_quantity
  ) => {
    editProductModalData.product_id = product_id;
    editProductModalData.product_name = product_name;
    editProductModalData.product_description = product_description;
    editProductModalData.product_price = product_price;
    editProductModalData.product_category = product_category;
    editProductModalData.product_quantity = product_quantity;
    setEditProductModal(true);
  };

  const editProductModalToggle = () => {
    setEditProductModal(!editProductModal);
  };

  const AddProductSubmit = async () => {
    if (
      addProductModalData.product_name === "" ||
      addProductModalData.product_description === "" ||
      addProductModalData.product_price === "" ||
      addProductModalData.product_category === "" ||
      addProductModalData.product_quantity === "" ||
      addProductImage === null
    )
      return toast.error("All Fields are required");
    const formData = new FormData();
    formData.append("product_name", addProductModalData.product_name);
    formData.append(
      "product_description",
      addProductModalData.product_description
    );
    formData.append("product_price", addProductModalData.product_price);
    formData.append("product_category", addProductModalData.product_category);
    formData.append("product_quantity", addProductModalData.product_quantity);

    formData.append("product_image_file", addProductImage.data);

    const result = await createProduct(formData, token);
    getProducts();
    setAddProductModalData((prev) => ({
      ...prev,
      product_name: "",
      product_description: "",
      product_price: "",
      product_category: "",
      product_quantity: "",
    }));
    setAddProductImage(null);
    setAddProductModal(false);
  };

  const EditProductSubmit = async () => {
    if (
      editProductModalData.product_name === "" ||
      editProductModalData.product_description === "" ||
      editProductModalData.product_price === "" ||
      editProductModalData.product_category === "" ||
      editProductModalData.product_quantity === ""
    )
      return toast.error("All Fields are required");
    const formData = new FormData();
    formData.append("product_id", editProductModalData.product_id);
    formData.append("product_name", editProductModalData.product_name);
    formData.append(
      "product_description",
      editProductModalData.product_description
    );
    formData.append("product_price", editProductModalData.product_price);
    formData.append("product_category", editProductModalData.product_category);
    formData.append("product_quantity", editProductModalData.product_quantity);

    if (editProductImageData !== null) {
      formData.append("product_image_file", editProductImageData?.data);
    }

    const result = await updateProduct(formData, token);
    getProducts();
    setEditProductModal(false);
    setEditProductImageData(null);
  };

  const DeleteProduct = async (product_id) => {
    const data = {
      product_id: product_id,
    };
    const result = await deleteProduct(data, token);
    getProducts();
    setDeleteModal(false);
  };

  const IncreaseProductQuantity = async (product_id, product_quantity) => {
    const data = {
      product_quantity_logic: +1,
      product_quantity: product_quantity,
    };
    const result = await updateProductQuntity(data, product_id, token);
    getProducts();
  };

  const DecreaseProductQuantity = async (product_id, product_quantity) => {
    const data = {
      product_quantity_logic: -1,
      product_quantity: product_quantity,
    };
    const result = await updateProductQuntity(data, product_id, token);
    getProducts();
  };

  const ChangeAvailability = async (product_id, product_quantity) => {
    if (product_quantity === 0) return toast.error("Product has no stock");
    const data = {
      product_id: product_id,
    };
    const result = await changeAvailable(data, token);
    getProducts();
    socket.emit("send_update_product_availability", product_id);
  };

  const ChangeUniversalAvailability = async (status) => {
    const data = {
      status: status,
    };
    const result = await changeUniversalAvailable(data, token);
    getProducts();
  };

  // React.useEffect(() => {
  //   const getData = setTimeout(() => {
  //     getProducts();
  //     getCategories();
  //   }, 2000);

  //   return () => clearTimeout(getData);
  // }, []);

  useEffect(() => {
    getProducts();
    getCategories();
  }, []);

  const getProducts = async () => {
    setLoading(true);
    API.product
      .GetAllProducts()
      .then((response) => {
        if (response) {
          setProductData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCategories = async () => {
    setLoading(true);
    try {
      const res = await getAllCategory();
      setCategoryData(res);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const _this = {
    loading,
    setLoading,
    productData,
    setProductData,
    loading,
    setLoading,
    addProductModal,
    setAddProductModal,
    AddProductSubmit,
    addProductModalToggle,
    addProductModalData,
    setAddProductModalData,
    editProductModal,
    setEditProductModal,
    editProductModalToggle,
    editProductModalData,
    setEditProductModalData,
    editProductImageData,
    setEditProductImageData,
    EditProductSubmit,
    openEditProductModal,
    categoryData,
    deleteModal,
    setDeleteModal,
    DeleteProduct,
    ChangeAvailability,
    ChangeUniversalAvailability,
    IncreaseProductQuantity,
    DecreaseProductQuantity,
    uploadImageFile,
    addProductImage,
    setAddProductImage,
  };
  return (
    <Container>
      <Body {..._this} />
    </Container>
  );
};
export default AddProduct;
