import React from "react";
import { useSelector } from "react-redux";
import cartEmptyImage from "../../assets/images/empty-cart.png";
import Bills from "./Bills";
import CartItems from "./CartItems";
import Address from "./Address";
import Payments from "./Payments";
import AddAddress from "./AddAddress";

const Body = (_this) => {
  const { cart_items } = useSelector((state) => state.cart);
  return (
    <>
      <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)] bg-black/5">
        {cart_items.length === 0 ? (
          <div className="flex flex-col justify-center items-center w-full sm:w-11/12 max-w-maxContent gap-y-8 py-8">
            <div className="bg-white rounded-full">
              <img src={cartEmptyImage} className="h-44 sm:h-60" />
            </div>
            <div className="text-center">
              <h5 className="text-lg md:text-xl text-black/80">
                Your cart is empty
              </h5>
              <p className="text-base font-light text-black/80">
                Look like you did not add any Items
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col-reverse md:flex-row w-full sm:w-11/12 max-w-maxContent gap-y-8 py-8">
            <div className="w-full md:w-7/12 flex flex-col gap-y-4">
              <Address {..._this} />
              <Payments {..._this} />
            </div>
            <div className="w-full md:w-5/12">
              <CartItems {..._this} />
              <Bills {..._this} />
            </div>
          </div>
        )}
      </div>
      {_this.addAddressSlider && <AddAddress {..._this} />}
    </>
  );
};

export default Body;
