import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Switch } from "antd";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ProductTable = (_this) => {
  return (
    <div className="relative overflow-x-auto overflow-y-scroll w-full">
      <table className="w-full h-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 border border-shadowColor uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Product Image
            </th>
            <th scope="col" className="px-6 py-3">
              Product Details
            </th>
            <th scope="col" className="px-6 py-3">
              Product Description
            </th>
            <th scope="col" className="px-6 py-3">
              Mange Stock
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {_this.productData.map((product) => {
            return (
              <tr
                className="bg-white dark:bg-gray-800 border border-shadowColor h-full"
                key={product._id}
              >
                <td
                  scope="row"
                  className="px-6 py-4 font-medium w-60 text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <img
                    className="h-auto min-h-32 min-w-32 md:h-44 object-cover"
                    src={`${BASE_URL + product.product_image}`}
                  />
                </td>
                <td className="px-6 py-4 text-left w-[30%]">
                  <p className="font-bold">{product.product_name}</p>
                  <p className="flex gap-x-2">
                    Price:
                    <span className="flex items-center font-bold">
                      <FaIndianRupeeSign />
                      {product.product_price}
                    </span>
                  </p>
                </td>
                <td className="px-6 py-4 text-left min-w-56 max-w-64 w-[30%]">
                  {product.product_description}
                </td>
                <td className="px-6 py-4">
                  <div className="flex flex-row items-center justify-between pr-2 xs:justify-center xs:w-max gap-2  md:gap-4 w-full">
                    <button
                      onClick={() =>
                        _this.DecreaseProductQuantity(
                          product._id,
                          product.product_quantity
                        )
                      }
                      className=" text-base text-greenColor"
                    >
                      <FaMinus />
                    </button>
                    <p className="text-xs sm:text-sm font-semibold flex">
                      {product.product_quantity}
                    </p>
                    <button
                      onClick={() =>
                        _this.IncreaseProductQuantity(
                          product._id,
                          product.product_quantity
                        )
                      }
                      className=" text-base text-greenColor"
                    >
                      <FaPlus />
                    </button>
                  </div>
                </td>
                <td className="flex flex-col h-full justify-around items-center">
                  <div>
                    <Switch
                      value={
                        !product?.universal_availability
                          ? false
                          : product.is_available
                      }
                      unCheckedChildren={false}
                      style={{
                        backgroundColor: !product?.universal_availability
                          ? "gray"
                          : !product.is_available
                          ? "gray"
                          : "#32CD32",
                      }}
                      onClick={(checked) => {
                        if (product?.universal_availability) {
                          _this.ChangeAvailability(
                            product._id,
                            product.product_quantity
                          );
                        }
                      }}
                    />
                  </div>

                  <div className="flex">
                    <button
                      disabled={_this.loading}
                      onClick={() =>
                        _this.openEditProductModal(
                          product._id,
                          product.product_name,
                          product.product_description,
                          product.product_price,
                          product.product_category,
                          product.product_quantity
                        )
                      }
                      className="px-2 transition-all duration-200 hover:scale-110 hover:text-caribbeangreen-300"
                    >
                      <FiEdit2 size={20} />
                    </button>
                    <button
                      disabled={_this.loading}
                      onClick={() => {
                        _this.setDeleteModal(product._id);
                      }}
                      title="Delete"
                      className="px-1 transition-all duration-200 hover:scale-110 hover:text-[#ff0000]"
                    >
                      <RiDeleteBin6Line size={20} />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
