import React from "react";
import { useLocation } from "react-router-dom";
import { FaIndianRupeeSign } from "react-icons/fa6";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Body = () => {
  const location = useLocation();
  const product = location.state;
  return (
    <div className="w-full flex justify-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)]">
      <div class="flex flex-col items-center w-full sm:w-11/12 max-w-maxContent gap-y-8 py-8">
        <div className="h-full w-full flex justify-center items-center">
          <div class="flex flex-row gap-7 h-[60%]">
            <div className="w-full lg:w-96 lg:h-[100%] h-64  rounded">
              <img
                alt="product_image"
                class="h-full"
                src={`${BASE_URL + product.product_image}`}
              />
            </div>
            <div class="flex flex-col gap-y-7">
              <div className="flex flex-col gap-y-4">
                <h2 class="text-sm title-font text-gray-500 tracking-widest">
                  ON SALE
                </h2>
                <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">
                  {product.product_name}
                </h1>
                <p class="leading-relaxed">{product.product_description}</p>
                <p class="title-font font-medium text-2xl text-gray-900 flex items-center gap-x-3">
                  MRP:
                  <span className="flex items-center">
                    <FaIndianRupeeSign className="text-xl" />
                    {product.product_price}
                  </span>
                </p>
              </div>
              <div class="flex justify-start gap-x-3">
                <button class="border-2  bg-primaryColor font-semibold text-white py-2 px-3 flex items-center gap-2 font-sans text-lg">
                  Add to Cart
                </button>
                <button class="border-2  bg-danger font-semibold text-white py-2 px-8 flex items-center gap-2 font-sans text-lg">
                  Buy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
