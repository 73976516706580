import React from "react";
import { Modal } from "antd";
import Upload from "./Upload";

const AddCategoryModal = (_this) => {
  return (
    <>
      <Modal
        title="Add Category"
        centered={true}
        open={_this.addCategoryModal}
        onOk={_this.AddCategorySubmit}
        onCancel={_this.addCategoryModalToggle}
        width={400}
        footer={
          <div className="flex items-center justify-end gap-x-2">
            <button
              className="cursor-pointer text-sm sm:text-base py-1.5 px-2.5 sm:py-2 sm:px-4 rounded-md bg-white hover:bg-shadowColor hover:text-primaryColor border border-shadowColor font-semibold"
              size="large"
              onClick={() => _this.addCategoryModalToggle()}
            >
              Cancel
            </button>
            <button
              size="large"
              onClick={() => _this.AddCategorySubmit()}
              type="primary"
              className="text-white text-sm sm:text-base py-1.5 px-2.5 sm:py-2 sm:px-4 bg-gradient-to-r from-yellow-100 via-yellow-100 to-yellow-200  shadow-xl shadow-shadowColor border-0 focus:outline-none hover:bg-primary-dark rounded-lg font-medium inline-flex items-center"
            >
              Submit
            </button>
          </div>
        }
      >
        <div className="flex justify-center my-">
          <form className="flex w-full md:w-11/12 flex-col items-center gap-y-4">
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Category Name <sup className="text-pink">*</sup>
              </p>
              <input
                required
                type="text"
                name="category_name"
                value={_this.addCategoryModalData.category_name}
                onChange={(e) => {
                  _this.setAddCategoryModalData((prev) => ({
                    ...prev,
                    category_name: e.target.value,
                  }));
                }}
                placeholder="Enter Category Name"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
              />
            </label>
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-subtext">
                Category Description <sup className="text-pink">*</sup>
              </p>
              <input
                required
                type="text"
                name="category_description"
                value={_this.addCategoryModalData.category_description}
                onChange={(e) => {
                  _this.setAddCategoryModalData((prev) => ({
                    ...prev,
                    category_description: e.target.value,
                  }));
                }}
                placeholder="Enter Description"
                className="w-full shadow-sm shadow-shadowColor outline-none border border-yellow-100 text-sm rounded-lg p-2.5"
              />
            </label>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
