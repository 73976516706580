import React from "react";
import Hero from "./Hero";
import AllProducts from "./AllProducts";
import { useSelector } from "react-redux";
import CartPopUp from "../../components/common/CartPopUp";
import ProductModal from "./ProductModal";
import HomeSkeleton from "./Skeleton";
const Body = (_this) => {
  const { cart_items } = useSelector((state) => state.cart);
  return (
    <>
      {_this?.loading ? (
        <HomeSkeleton />
      ) : (
        <div className="w-full flex flex-col items-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)] font-sans">
          <div className="w-full md:pt-4 md:pb-6">
            <Hero />
          </div>
          <div className="flex flex-col w-11/12 max-w-maxContent gap-y-8">
            <AllProducts {..._this} />
          </div>
        </div>
      )}
      {/* {cart_items.length > 0 && (
        <div className="fixed bottom-[61px] md:bottom-0 w-full transition-all ease-in-out delay-500">
          <div className="max-w-maxContent mx-auto">
            <CartPopUp />
          </div>
        </div>
      )} */}
      <ProductModal {..._this} />
    </>
  );
};

export default Body;
