import { Empty } from "antd";
import React, { useState, useEffect } from "react";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { Link } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const AllProducts = (_this) => {
  //Placeholder auto
  const fixedText = "Search for ";
  const dynamicTexts = ["Kababs", "Fish Kababs", "Veg Thali"];
  const [TextIndex, setTextIndex] = useState(0);
  const [typedText, setTypedText] = useState("");

  useEffect(() => {
    let timeoutId;
    let charIndex = 0;
    const typeNextChar = () => {
      setTypedText((prevText) =>
        dynamicTexts[TextIndex].slice(0, charIndex + 1)
      );
      charIndex += 1;

      if (charIndex <= dynamicTexts[TextIndex].length) {
        timeoutId = setTimeout(typeNextChar, 80);
      } else {
        charIndex = 0;
        setTextIndex((prevIndex) => (prevIndex + 1) % dynamicTexts.length);
        timeoutId = setTimeout(typeNextChar, 1500);
      }
    };
    timeoutId = setTimeout(typeNextChar, 1000);
    return () => clearTimeout(timeoutId);
  }, [TextIndex, dynamicTexts.length]);

  const placeholderText = `${fixedText}'${typedText}'`;
  return (
    <div className="flex flex-col gap-y-9">
      <div>
        {_this.productData.length === 0 ? (
          <div className="pt-16">
            <Empty description={<span>No Items</span>} />
          </div>
        ) : (
          <div className="w-full flex flex-col items-center justify-center gap-y-8">
            <div className="w-full">
              {_this.productData.map((categoryProduct, index) => {
                return (
                  <div
                    key={index}
                    id={categoryProduct.category_id}
                    className="w-full flex flex-col gap-y-6"
                  >
                    <div className="w-full border-b border-black/10 pt-10 pb-2">
                      <h5 className="text-lg font-bold text-black/80">
                        {categoryProduct.category}
                      </h5>
                    </div>
                    {categoryProduct?.products?.length === 0 ? (
                      <div className="pt-16">
                        <Empty description={<span>No Items</span>} />
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full gap-2 ">
                        {categoryProduct?.products?.map((product) => {
                          return (
                            <Link
                              to={{ pathname: "/product" }}
                              state={product}
                              key={product._id}
                              className="flex justify-center items-center w-full py-3 border border-black/5 hover:shadow-xl"
                            >
                              <div className="hidden sm:flex flex-col gap-y-4 min-w-full">
                                <img
                                  alt="product_image"
                                  src={`${BASE_URL + product.product_image}`}
                                  className={`h-46 w-40 mx-auto ${
                                    (!product.universal_availability ||
                                      !product?.is_available) &&
                                    "grayscale"
                                  }`}
                                />
                                <div className="flex flex-col gap-4 px-5">
                                  <div className="flex flex-col gap-y-1 mx-auto">
                                    <p className="text-sm text-wrap font-sans">
                                      {product.product_name}
                                    </p>
                                    <p
                                      onClick={() => {
                                        _this?.setProductDetailsModal(product);
                                      }}
                                      className="text-xs cursor-pointer text-blue-100"
                                    >
                                      Show Details
                                    </p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p className="flex items-center font-semibold font-sans">
                                      <FaIndianRupeeSign />
                                      {product.product_price}
                                    </p>
                                    {product.universal_availability &&
                                    product?.is_available ? (
                                      <button
                                        className="border-2  bg-primaryColor font-semibold text-white py-1 px-2 flex items-center gap-2 font-sans"
                                        onClick={() =>
                                          _this.addToCartHandler(product)
                                        }
                                      >
                                        Add to Cart
                                      </button>
                                    ) : (
                                      <p>Not Available</p>
                                    )}
                                    {product.universal_availabilityy === true}
                                  </div>
                                </div>
                              </div>
                              <div className="sm:hidden w-full h-full flex flex-col justify-between">
                                <div className="flex flex-row gap-x-3 w-full">
                                  <div className="relative w-44">
                                    <img
                                      alt="product_image"
                                      src={`${
                                        BASE_URL + product.product_image
                                      }`}
                                      className={`h-20 xs:h-24 min-h-24 max-h-24 object-cover rounded-lg ${
                                        (!product.universal_availability ||
                                          !product?.is_available) &&
                                        "grayscale"
                                      }`}
                                    />
                                  </div>
                                  <div className="w-full flex flex-col justify-around pr-0.5 pb-1 xs:pr-0 xs:pb-0 xs:flex-row xs:justify-between">
                                    <div className="flex flex-col justify-around min-w-20 gap-1">
                                      <div className="flex flex-col gap-1">
                                        <p className="text-xs xs:text-sm sm:text-base">
                                          {product.product_name}
                                        </p>
                                        <p
                                          onClick={() => {
                                            _this?.setProductDetailsModal(
                                              product
                                            );
                                          }}
                                          className="text-xs cursor-pointer text-blue-100"
                                        >
                                          Show Details
                                        </p>
                                      </div>
                                      <p className="flex items-center text-xs xs:text-sm">
                                        <FaIndianRupeeSign />
                                        {product.product_price}
                                      </p>
                                    </div>
                                    <button
                                      onClick={() => {
                                        product.is_available &&
                                          product.universal_availability &&
                                          _this.addToCartHandler(product);
                                      }}
                                      className={`xs:h-full font-bold text-white ${
                                        !product.universal_availability ||
                                        !product?.is_available
                                          ? "bg-black/20"
                                          : "bg-primaryColor/90"
                                      }  xs:rounded-tr-md xs:rounded-br-md px-3`}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                                <div className="h-[1px] bg-black/10 mt-4"></div>
                              </div>
                            </Link>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllProducts;
