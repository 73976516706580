import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Navbar from "../../components/Navbar";
import Body from "./Body";
import {
  addAddress,
  getAllAddress,
  updateAddress,
  deleteAddress,
} from "../../services/operations/addressApi";

const ManageAddress = () => {
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [addressData, setaddressData] = useState([]);
  const [deleteAddressModal, setDeleteAddressModal] = useState(false);
  const [deleteAddressData, setDeleteAddressData] = useState("");
  const [editAddressSlider, setEditAddressSlider] = useState(false);
  const [addAddressSlider, setAddAddressSlider] = useState(false);
  const [editAddressData, setEditAddressData] = useState({
    address_id: "",
    street: "",
    city: "",
    state: "",
    land_mark: "",
    postal_code: "",
  });
  const [addAddressData, setAddAddressData] = useState({
    street: "",
    city: "",
    state: "",
    land_mark: "",
    postal_code: "",
  });

  const editAddressSubmit = async () => {
    if (editAddressData.street === "")
      return toast.error("Please enter street");
    else if (editAddressData.city === "")
      return toast.error("Please enter city");
    else if (editAddressData.state === "")
      return toast.error("Please enter state");
    else if (editAddressData.land_mark === "")
      return toast.error("Please enter landmark");
    else if (editAddressData.postal_code === "")
      return toast.error("Please enter pin code");

    const formData = new FormData();
    formData.append("address_id", editAddressData.address_id);
    formData.append("street", editAddressData.street);
    formData.append("city", editAddressData.city);
    formData.append("state", editAddressData.state);
    formData.append("land_mark", editAddressData.land_mark);
    formData.append("postal_code", editAddressData.postal_code);

    const res = await updateAddress(token, formData);
    getAllAddressOfUser();

    setEditAddressData((prev) => ({
      ...prev,
      address_id: "",
      street: "",
      city: "",
      state: "",
      land_mark: "",
      postal_code: "",
    }));
    setEditAddressSlider(false);
  };

  const addAddressSubmit = async () => {
    if (addAddressData.street === "") return toast.error("Please enter street");
    else if (addAddressData.city === "")
      return toast.error("Please enter city");
    else if (addAddressData.state === "")
      return toast.error("Please enter state");
    else if (addAddressData.land_mark === "")
      return toast.error("Please enter landmark");
    else if (addAddressData.postal_code === "")
      return toast.error("Please enter pin code");

    const formData = new FormData();
    formData.append("street", addAddressData.street);
    formData.append("city", addAddressData.city);
    formData.append("state", addAddressData.state);
    formData.append("land_mark", addAddressData.land_mark);
    formData.append("postal_code", addAddressData.postal_code);

    const res = await addAddress(token, formData);
    getAllAddressOfUser();

    setAddAddressData((prev) => ({
      ...prev,
      street: "",
      city: "",
      state: "",
      land_mark: "",
      postal_code: "",
    }));
    setAddAddressSlider(false);
  };

  const deleteAddressSubmit = async () => {
    const address_id = deleteAddressData._id;
    const res = await deleteAddress(token, { address_id });
    setDeleteAddressModal(false);
    getAllAddressOfUser();
    setDeleteAddressData("");
  };

  useEffect(() => {
    getAllAddressOfUser();
  }, []);

  const getAllAddressOfUser = async () => {
    setLoading(true);
    try {
      if (token) {
        const res = await getAllAddress(token);
        setaddressData(res);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const _this = {
    loading,
    setLoading,
    addressData,
    setaddressData,
    editAddressSlider,
    setEditAddressSlider,
    editAddressData,
    setEditAddressData,
    editAddressSubmit,
    deleteAddressModal,
    setDeleteAddressModal,
    deleteAddressData,
    setDeleteAddressData,
    deleteAddressSubmit,
    addAddressSlider,
    setAddAddressSlider,
    addAddressSubmit,
    addAddressData,
    setAddAddressData,
  };
  return (
    <>
      <Navbar />
      <div className="mt-[65px] mb-[60px] md:mb-[0px] md:mt-[90px]">
        <Body {..._this} />
      </div>
    </>
  );
};

export default ManageAddress;
