import React from "react";
import Body from "./Body";

const Login = () => {
  const _this = {};
  return (
    <>
      <Body {..._this} />
    </>
  );
};

export default Login;
