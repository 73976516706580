import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import heroImageSlide from "../../assets/images/heroslide.jpg";
import heroImageSlide1 from "../../assets/images/heroslide1.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
const Hero = () => {
  return (
    <div className="h-[300px] sm:h-[400px] md:h-[350px] lg:h-60vh hero">
      <Swiper
        style={{
          "--swiper-pagination-color": "#00a752",
          "--swiper-pagination-bullet-inactive-color": "#999999",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-horizontal-gap": "6px",
        }}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        speed={1500}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="w-full h-full bg-white">
            <div className="max-w-maxContent flex flex-col-reverse md:flex-row justify-around items-center mx-auto">
              <div className="flex flex-col gap-y-3">
                <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold">
                  Welcome to
                </h1>
                <h1 className="text-[30px] sm:text-[35px] md:text-[50px] lg:text-[70px] font-semibold text-primaryColor font-sans">
                  ছাত্রবন্ধু প্রকাশন
                </h1>
              </div>
              <img
                src={heroImageSlide}
                alt="heroimage"
                className="h-36 sm:h-60 md:h-72 lg:h-96"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-full bg-white">
            <div className="max-w-maxContent flex flex-col-reverse md:flex-row justify-around items-center mx-auto">
              <div className="flex flex-col gap-y-7">
                <h1 className="text-[35px] sm:text-[28px] md:text-[40px] lg:text-[50px] font-semibold text-black/75 font-sans">
                  ফুলকুঁড়ি সিরিজ
                </h1>
                <buton className="text-sm bg-primaryColor max-w-28 mx-auto text-white px-3 py-1 cursor-pointer">
                  See More
                </buton>
              </div>
              <img
                src={heroImageSlide1}
                alt="heroimage"
                className="h-36 sm:h-60 md:h-72 lg:h-[500px] "
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Hero;
