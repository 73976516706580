import React from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const Payments = (_this) => {
  const { token } = useSelector((state) => state.auth);
  return (
    <div className="w-11/12 mx-auto overflow-x-hidden bg-white">
      <div className="flex flex-col gap-3 p-4">
        <h4 className="font-semibold text-black/70">Payments</h4>
        <div className="h-[1px] bg-black/5"></div>
        <div className="w-full mx-auto p-5 flex flex-col gap-y-6">
          <div>
            <ul className="grid w-full gap-6 md:grid-cols-2">
              <li>
                <input
                  type="radio"
                  id="online_radio"
                  name="payment_mod"
                  value="Online"
                  className="hidden peer"
                  onChange={(e) => {
                    _this.setPaymetMod(e.target.value);
                  }}
                  required
                />
                <label
                  htmlFor="online_radio"
                  className="inline-flex items-center justify-between w-full p-3 md:p-4 text-black/65 bg-white border border-black/40 rounded-lg peer-checked:bg-greenColor/5 cursor-pointer peer-checked:border-greenColor peer-checked:text-greenColor"
                >
                  <div className="block">
                    <div className="w-full text-sm md:text-lg md:font-semibold">
                      Pay Online
                    </div>
                  </div>
                </label>
                {/* <label
                  htmlFor="online_radio"
                  className="inline-flex items-center justify-between w-full p-3 md:p-4 text-black/40 bg-black/5 rounded-lg select-none"
                >
                  <div className="block">
                    <div className="w-full text-sm md:text-lg md:font-semibold">
                      Pay Online
                    </div>
                  </div>
                </label> */}
              </li>

              <li>
                {/* {token !== null ? (
                  <>
                    <input
                      type="radio"
                      id="cod_radio"
                      name="payment_mod"
                      value="Cod"
                      className="hidden peer"
                      onChange={(e) => {
                        _this.setPaymetMod(e.target.value);
                      }}
                    />
                    <label
                      htmlFor="cod_radio"
                      className="inline-flex items-center justify-between w-full p-3 md:p-4 text-black/65 bg-white border border-black/40 rounded-lg cursor-pointer peer-checked:bg-greenColor/5 peer-checked:border-greenColor peer-checked:text-greenColor"
                    >
                      <div className="block">
                        <div className="w-full text-sm md:text-lg md:font-semibold">
                          Pay on Delivey
                        </div>
                      </div>
                    </label>
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        toast.error("Login to order in Cod method");
                      }}
                      className="select-none inline-flex items-center justify-between w-full p-3 md:p-4 bg-[#EBEBE4] border border-[#EBEBE4] text-[#C6C6C6] rounded-lg cursor-pointer"
                    >
                      <p className="w-full text-sm md:text-lg md:font-semibold">
                        Pay on Delivey
                      </p>
                    </div>
                  </>
                )} */}
                <input
                  type="radio"
                  id="cod_radio"
                  name="payment_mod"
                  value="Cod"
                  className="hidden peer"
                  onChange={(e) => {
                    _this.setPaymetMod(e.target.value);
                  }}
                />
                <label
                  htmlFor="cod_radio"
                  className="inline-flex items-center justify-between w-full p-3 md:p-4 text-black/65 bg-white border border-black/40 rounded-lg cursor-pointer peer-checked:bg-greenColor/5 peer-checked:border-greenColor peer-checked:text-greenColor"
                >
                  <div className="block">
                    <div className="w-full text-sm md:text-lg md:font-semibold">
                      Pay on Delivey
                    </div>
                  </div>
                </label>
              </li>
            </ul>
          </div>
          <button
            onClick={() => _this.onOrderPlaced()}
            className="w-full bg-greenColor py-2 text-white font-bold text-base"
          >
            Place order
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payments;
