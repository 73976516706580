import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const CategoryTable = (_this) => {
  return (
    <div className="relative overflow-x-auto overflow-y-auto">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 border border-shadowColor uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Category Name
            </th>
            <th scope="col" className="px-6 py-3">
              Category Description
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {_this.categoryData.map((category) => {
            return (
              <tr
                className="bg-white dark:bg-gray-800 border border-shadowColor"
                key={category._id}
              >
                <td className="px-6 py-4 text-center">
                  {category.category_name}
                </td>
                <td className="px-6 py-4 text-left">
                  {category.category_description}
                </td>
                <td className="px-6 py-4 text-center">
                  <button
                    // disabled={_this.loading}
                    onClick={() =>
                      _this.openEditCategoryModal(
                        category._id,
                        category.category_name,
                        category.category_description
                      )
                    }
                    className="px-2 transition-all duration-200 hover:scale-110 hover:text-caribbeangreen-300"
                  >
                    <FiEdit2 size={20} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
