import React, { useState } from "react";
import { Drawer } from "antd";
import { AiOutlineClose } from "react-icons/ai";

const AddAddress = (_this) => {
  return (
    <>
      <Drawer
        title={
          <div className="flex items-center justify-between gap-x-5">
            <div></div>
            <p>Add Address</p>
            <AiOutlineClose
              onClick={() => _this.setAddAddressSlider(false)}
              size={22}
              className="text-gray-medium cursor-pointer"
            />
          </div>
        }
        placement="left"
        onClose={() => _this.setAddAddressSlider(false)}
        open={_this.addAddressSlider}
        width={window.innerWidth > 600 ? 600 : window.innerWidth}
        closable={false}
        key="left"
        footer={
          <div className="flex items-center justify-end gap-x-4 text-white font-bold py-3">
            <button
              onClick={() => _this.setAddAddressSlider(false)}
              className="border border-primaryColor text-primaryColor py-2 px-4"
            >
              Cancel
            </button>
            <button
              onClick={() => _this.addAddressSubmit()}
              className="bg-primaryColor py-2 px-4"
            >
              Submit
            </button>
          </div>
        }
      >
        <div className="flex w-full justify-center">
          <div className="w-full flex flex-col items-center gap-y-4 sm:w-9/12">
            <form className="flex w-full flex-col gap-y-6">
              <label className="relative">
                <input
                  required
                  type="text"
                  name="street"
                  value={_this.addAddressData.street}
                  onChange={(e) => {
                    _this.setAddAddressData((prev) => ({
                      ...prev,
                      street: e.target.value,
                    }));
                  }}
                  placeholder="Street"
                  className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                />
                <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                  Street <sup className="text-pink-200">*</sup>
                </span>
              </label>
              <label className="relative">
                <input
                  required
                  type="text"
                  name="city"
                  value={_this.addAddressData.city}
                  onChange={(e) => {
                    _this.setAddAddressData((prev) => ({
                      ...prev,
                      city: e.target.value,
                    }));
                  }}
                  placeholder="Enter You City"
                  className="w-full outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                />
                <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                  City <sup className="text-pink-200">*</sup>
                </span>
              </label>
              <label className="relative">
                <input
                  required
                  type="text"
                  name="state"
                  value={_this.addAddressData.state}
                  onChange={(e) => {
                    _this.setAddAddressData((prev) => ({
                      ...prev,
                      state: e.target.value,
                    }));
                  }}
                  placeholder="Enter You State"
                  className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                />
                <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                  State <sup className="text-pink-200">*</sup>
                </span>
              </label>
              <label className="relative">
                <input
                  required
                  type="text"
                  name="land_mark"
                  value={_this.addAddressData.land_mark}
                  onChange={(e) => {
                    _this.setAddAddressData((prev) => ({
                      ...prev,
                      land_mark: e.target.value,
                    }));
                  }}
                  placeholder="Enter You Landmark"
                  className="w-full shadow-sm outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                />
                <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50 absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                  Landmark <sup className="text-pink-200">*</sup>
                </span>
              </label>
              <label className="relative">
                <input
                  required
                  type="text"
                  name="postal_code"
                  value={_this.addAddressData.postal_code}
                  onChange={(e) => {
                    _this.setAddAddressData((prev) => ({
                      ...prev,
                      postal_code: e.target.value,
                    }));
                  }}
                  placeholder="Enter You Pin code"
                  className="w-full outline-none border border-primaryColor text-sm p-2.5 placeholder:opacity-0"
                />
                <span className="mb-1 text-[0.875rem] leading-[1.375rem] font-semibold text-subtext/50  absolute left-2 top-[9px] px-1 transition duration-200 input-text">
                  Pin <sup className="text-pink-200">*</sup>
                </span>
              </label>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default AddAddress;
